.card-container {
    border: 1px solid #eee;
    height: 100%;
    position: relative;
    border-radius: 8px;
}

.card-main-image {
    border: none;
}

.btn-view-offer {
    background: #FCC01533;
    border-radius: 0px 0px 4px 4px;

}

.btn-view-offer:hover {
    background: #fcbe1550;
}

.btn-activate-offer {
    background: #B3D7FF52;
    border-radius: 0px 0px 4px 4px;

}

.btn-activate-offer:hover {
    background: #b3d6ff79;
}

.btn-activated-offer {
    background: #EFEFEF;
    border-radius: 0px 0px 4px 4px;

}

.card-title {
    margin-bottom: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 1rem !important;
}

.btn-sign-activate-offer {
    border-radius: 0px 0px 4px 4px !important;
}

.offerOptionslist {
    color: #666666;
}

.of-badge {
    /* background-color: #FFEF99; */
    color: #000;
}

.offercardlink {
    height: 100%;
    display: block;
    border: 1px solid #D3D3D3;
    border-radius: 12px;
    text-decoration: none;
}
