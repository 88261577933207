
.od-merchant-logo{
    width: 120px !important;
    height: 120px;
    border-radius: 50%;
    border: 2px solid #eee;
}
.od-offer-title{
    font-size: 22px;
    line-height: 33px;
}
.od-carousel-item{
    height: auto !important;
}
.od-carousel-indicators{
    bottom: -45px;
}
.ow-tnc p, .ow-tnc li{
    font-size: 14px !important;
}

.ow-tnc ul, .ow-tnc ol{
    padding-left: 1rem;
}

.od-offer-desscription, .od-offer-desscription p{
    font-size: 14px !important;
    color: #000;
}
.offerOptionslist {
    text-align: left; /* Align everything to the left */
    padding: 0; /* Adjust padding if necessary */
    max-width: 100%; /* Allow full width usage */
}

.offerOptionslist ul {
    list-style-type: none; /* Remove bullets */
    padding: 0; /* Remove padding */
    margin: 0; /* Remove margin */
}

.offerOptionslist li {
    margin-bottom: 8px; /* Add space between list items */
}

.btn-visit-website {
    background-color: #004346; /* Dark background */
    color: white; /* White text */
    border: none;
    padding: 10px 15px;
    border-radius: 50px; /* Rounded edges */
    display: inline-flex; /* Align icon and text inline */
    align-items: center; /* Center icon and text vertically */
    font-size: 14px;
    cursor: pointer;
    text-decoration: none; /* Remove underline */
    margin-top: 16px; /* Add space between the button and text above */
}

.btn-visit-website i {
    margin-right: 8px; /* Space between icon and text */
}

.btn-visit-website:hover {
    background-color: #343a40; /* Slightly lighter background on hover */
}
