

.recoffercontainer{
    width: 100%;
    display: flex;
}

@media (max-width: 767px) {
    .recoffercontainer {
        width: 340%;
    }
}