.banner-text {
    /* font-size: 45px; */
    /* line-height: 36px; */
    font-weight: 500;

}

.carousel-item {
    background-position: right top;
    background-size: cover;
    height: 243px;
}

.banner-heading {
    font-family: "GT Walsheim Pro" !important;
    font-size: 45px;
    font-weight: bold;
}

.banner-desc {
    font-family: "GT Walsheim Pro" !important;
    font-size: 26px;
    font-weight: normal;
    line-height: 30px;
}


@media (max-width: 767px) {
    .carousel-item {
        height: 195px;
        background-position: 80% 0;
    }

    .carousel-item h1 {
        font-size: 23px;
    }

    .banner-desc {

        font-size: 14px;
        line-height: 18px;
    }
}

.banner-text-container {
    justify-content: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
}