.welcome-page-text {
    font-family: 'Verdana', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
    background-color: #f4f4f4;
    font-size: 60px;
    font-weight: bold;
    letter-spacing: 1px;
  }
  
  .welcome-page-blue {
    color: #0d6efd;
  }