@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

body {
  background-color: #f0f0f0;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.form-title {
  font-family: "Roboto", sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #333;
}

.form-label {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.btn-primary {
  background-color: #333;
  border-color: #333;
}

.btn-primary:hover {
  background-color: #555;
  border-color: #555;
}
