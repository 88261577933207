@import url("https://fonts.googleapis.com/css?family=Josefin+Sans:300,400,600,700&display=swap");

body {
  font-family: 'SFProText';
  font-weight: normal;
  font-style: normal;
  color: #000000;
  background-color: #fff !important;
}

* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

a:focus,
input:focus,
textarea:focus,
button:focus {
  text-decoration: none;
  outline: none;
}

a:focus,
a:hover {
  text-decoration: none;
}



.ow-btn-primary {
  background-color: #FFD23A !important;
  color: #000;
  border: 0;
  border-radius: 0;
  width: max-content;

}

.form-control:focus, input:focus, select:focus{
  border: var(--bs-border-width) solid var(--bs-border-color) !important;
  box-shadow: none !important;
}



p {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #747E88;
  margin: 0px;
}

.bg_cover {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

/*===== All Button Style =====*/
.main-btn {
  display: inline-block;
  font-weight: 600;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  padding: 0 28px;
  font-size: 14px;
  
  color: #000000;
  cursor: pointer;
  z-index: 5;
  -webkit-transition: all 0.4s ease-out 0s;
  -moz-transition: all 0.4s ease-out 0s;
  -ms-transition: all 0.4s ease-out 0s;
  -o-transition: all 0.4s ease-out 0s;
  transition: all 0.4s ease-out 0s;
  background-color: #FFD23A;
  border-radius: 0;
  font-family: 'SFProText';
}

/* @media (max-width: 767px) {
  .main-btn {
    padding: 0px 20px;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
  }
} */

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .main-btn {
    padding: 0px 20px;
    height: 46px;
    line-height: 46px;
    font-size: 14px;
  }
}

.main-btn:hover {
  background-color: #004346 !important;
  color: #fff;
}

.main-btn.main-btn-2 {
  background-color: #fff;
  color: #0898E7;
  border-color: #0898E7;
}

.main-btn.main-btn-2:hover {
  background-color: #004346;
  border-color: #004346;
  color: #fff;
}

/*===== All Button Style =====*/
.section_title{
  display: grid;
  align-items: center;
}
.section_title .sub_title {
  font-size: 25px;
  font-weight: 500;
  color: #000000;
  font-family: 'SFProText';
}

@media (max-width: 767px) {
  .section_title .sub_title {
    font-size: 22px;
  }

  .category-title {
    font-size: 22px;
  }
}

.section_title .title {
  font-size: 40px;
  font-weight: 700;
  margin-top: 25px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .section_title .title {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .section_title .title {
    font-size: 24px;
  }
}

/*===========================
      02.HEADER css 
===========================*/
@media only screen and (min-width: 1400px) {
  .header_area {
    padding: 0 0;
  }
}

.navbar-brand img {
  width: 136px;
}

.header-searchbar {
  width: 220px;
  height: 40px;
  background: #FAFAFA;
  border: #D3D3D3 1px solid;
  border-radius: 4px;
  margin-left: 26px;
}

.search-icon {
  padding: 5px 5px 5px 10px;
  float: left;
}

.search-input {
  background: #FAFAFA;
  font-family: 'SFProText';
  font-size: 16px;
  font-weight: 300;
  border: none;
  padding-top: 6px;
  float: left;
}

.hero-img {
  background-image: url(../images/herobanner.png);
  background-size: cover;
  background-position: right top;
}

.grid-col {
  text-align: center;
  width: 70px;
}

/* .grid-icon{margin: 0 auto; width: 32px;} */
/* .grid-label{font-size: 14px; font-weight: 200; font-family: 'SFProText';} */
.category-title {
  font-size: 25px;
  padding-bottom: 52px;
  padding-top: 46px;
  font-family: 'SFProText';
}

.offerGridImage img {
  border-radius: 12px 12px 0 0;
}

.offerGridContent {
  width: 100%;
  /* float: left; */
  /* border-left: #D3D3D3 1px solid;
  border-right: #D3D3D3 1px solid;
  border-bottom: #D3D3D3 1px solid;
  border-radius: 0 0 12px 12px; */
  /* margin-bottom: 20px; */
  padding: 20px;
}

.merchantLogo img {
  width: 84px;
  border: 2px solid #eee; 
}

.merchantLogo {
  float: left;
}

.merchantName {
  float: left;
  padding-top: 32px;
  padding-left: 12px;
  font-family: 'SFProText';
  font-size: 16px;
  font-weight: 500;
}

.offerTitle {
  font-family: 'SFProText';
  font-size: 16px;
  font-weight: 500;
  color: #1D1D1D;
  width: 100%;
  /* float: left; */
  padding-top: 15px;
}

.offerOptionslist {
  width: 100%;
  /* float: left; */
  padding-top: 10px;
}

.offerOptionslist li {
  /* list-style: inside; */
  /* font-family: 'SFProText'; */
  font-size: 14px;
  color: #666666;
  /* font-weight: 100; */
  line-height: 20px;
}

.onlineStore {
  background-image: url(../images/offers/onlinetag.png);
  background-repeat: no-repeat;
  background-size: 14px;
  width: 100%;
  /* float: left; */
  text-transform: uppercase;
  font-family: 'SFProText';
  font-size: 12px;
  color: #1D1D1D;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding-left: 20px;
  margin-top: 16px;
  bottom:5px;
  margin-left: 15px;
}

/*===== NAVBAR =====*/
.header_navbar {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  background-color: #fff;
}

@media only screen and (min-width: 1400px) {
  .header_navbar {
    padding: 0 70px;
  }
}

.sticky {
  position: fixed;
  z-index: 99;
  -webkit-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 50px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.sticky .navbar {
  padding: 0;
}


@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sticky .navbar {
    padding: 15px 0;
  }
}

@media (max-width: 767px) {
  .sticky .navbar {
    padding: 15px 0;
  }
}

@media only screen and (min-width: 375px) and (max-width: 576px) {
  .header-searchbar {
    width: 160px;
    margin-left: 0px;
  }

  .search-input {
    width: 65%;
  }
}

.navbar {
  padding: 0;
  border-radius: 5px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar {
    padding: 20px 0;
  }
}

@media (max-width: 767px) {
  .navbar {
    padding: 20px 0;
  }
}

.navbar-brand {
  padding: 0;
}

.navbar-toggler {
  padding: 0;
}

.navbar-toggler .toggler-icon {
  width: 20px;
  height: 2px;
  background-color: #4A4A4A;
  display: block;
  margin: 5px 0;
  margin-right: 12px;
  position: relative;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.navbar-toggler.active .toggler-icon:nth-of-type(1) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 7px;
}

.navbar-toggler.active .toggler-icon:nth-of-type(2) {
  opacity: 0;
}

.navbar-toggler.active .toggler-icon:nth-of-type(3) {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  top: -7px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px;
  }
}

@media (max-width: 767px) {
  .navbar-collapse {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 9;
    -webkit-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 20px 0px rgba(0, 0, 0, 0.1);
    padding: 5px 12px;
  }
}

.navbar-nav {
  margin-right: 30px;
}

.navbar-nav .nav-item {
  position: relative;
}

.navbar-nav .nav-item a {
  font-family: 'SFProText';
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 30px 26px;
  position: relative;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item a {
    display: block;
    padding: 4px 0;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item a {
    display: block;
    padding: 4px 0;
  }
}

.navbar-nav .nav-item a::before {
  position: absolute;
  content: '';
  width: 0;
  height: 3px;
  top: 0;
  left: 0;

  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item a::before {
    display: none;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item a::before {
    display: none;
  }
}

.navbar-nav .nav-item.active>a,
.navbar-nav .nav-item:hover>a {
  color: #004346;
}

.navbar-nav .nav-item.active>a::before,
.navbar-nav .nav-item:hover>a::before {
  width: 100%;
}

.navbar-nav .nav-item:hover .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item:hover .sub-menu {
    top: 0;
  }
}

.navbar-nav .nav-item .sub-menu {
  width: 200px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 110%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: none;
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: 767px) {
  .navbar-nav .nav-item .sub-menu {
    position: relative;
    width: 100%;
    top: 0;
    display: none;
    opacity: 1;
    visibility: visible;
  }
}

.navbar-nav .nav-item .sub-menu li {
  display: block;
}

.navbar-nav .nav-item .sub-menu li a {
  display: block;
  padding: 8px 20px;
  color: #222;
}

.navbar-nav .nav-item .sub-menu li a.active,
.navbar-nav .nav-item .sub-menu li a:hover {
  padding-left: 25px;
  color: #0898E7;
}

.navbar-nav .sub-nav-toggler {
  display: none;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #222;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}

@media (max-width: 767px) {
  .navbar-nav .sub-nav-toggler {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    background: none;
    color: #222;
    font-size: 18px;
    border: 0;
    width: 30px;
    height: 30px;
  }
}

.navbar-nav .sub-nav-toggler span {
  width: 8px;
  height: 8px;
  border-left: 1px solid #222;
  border-bottom: 1px solid #222;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  position: relative;
  top: -5px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-btn {
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

@media (max-width: 767px) {
  .navbar-btn {
    position: absolute;
    top: 50%;
    right: 50px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.navbar-btn .main-btn {
  padding: 0 20px;
  height: 45px;
  line-height: 45px;
  color: #0898E7;
  background-color: transparent;
  border: 2px solid #0898E7;
}

.navbar-btn .main-btn:hover {
  background-color: rgba(8, 152, 231, 0.3);
}

/*===== Header =====*/
.brand_single {
  margin-left: -15px;
  margin-right: -15px;
}

.single_brand {
  width: 20%;
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 30px;
}

@media (max-width: 767px) {
  .single_brand {
    width: 50%;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .single_brand {
    width: 33.33%;
  }
}

.single_brand img {
  width: 50%;
}

.single_brand img:hover {
  opacity: 0.5;
}



/*===== Footer =====*/
.footer_area {
  float: left;
  background-color: #F5F5F5;
  width: 100%;
  padding-bottom: 50px;
}

.footer_wrapper {
  position: relative;
}

.footer-content {
  float: right;
}

.footer-linkstitle {
  font-family: 'SFProText';
  font-size: 18px;
  font-weight: 700;
  color: #4A4A4A;
  padding-bottom: 12px;
}

.footerLink li {
  list-style: none;
  font-family: 'SFProText';
  font-size: 14px;
  color: #4A4A4A;
  padding-top: 14px;
}

.footerLink li a {
  color: #4A4A4A;
}

.footerLink li a:hover {
  color: #4A4A4A;
  text-decoration: underline;
}

.footerLinks {
  float: left;
  padding-left: 120px;
}

.copyrights {
  font-family: 'SFProText';
  font-size: 14px;
  color: #4A4A4A;
  text-align: right;
  width: 100%;
  float: right;
  padding-top: 80px;
}


@media (max-width: 767px) {}

@media only screen and (min-width: 576px) and (max-width: 767px) {}

@media only screen and (min-width: 375px) and (max-width: 576px) {
  .footerLinks {
    padding-left: 0;
    width: 100%;
    padding-bottom: 50px;
  }

  .footer-content {
    float: left;
  }

  .copyrights {
    text-align: left;
    padding-top: 0px;
  }
}


.form-select {
  font-family: 'SFProText';
  font-size: 13px;
  color: #4A4A4A;
  border-radius: 40px;
  padding: 8px;
  border: none;


}

.selector {
  float: right;
  margin-top: -5px;
  border: #D0DAE2 solid 1.5px;
  border-radius: 40px;
  /* padding-right: 8px; */
}