
.skeleton{    
    -webkit-animation: SkeletonAnimation 15s ease infinite;
    -moz-animation: SkeletonAnimation 15s ease infinite;
    -o-animation: SkeletonAnimation 15s ease infinite;
    animation: SkeletonAnimation 0.5s linear infinite alternate;
}
.skeleton-cbg{
    background-color: #999999;
}
@keyframes SkeletonAnimation {
    to{
        opacity: 0.8;
    }
}
@-webkit-keyframes SkeletonAnimation {
    to{
        opacity: 0.8;
    }
}
@-moz-keyframes SkeletonAnimation {
    to{
        opacity: 0.8;
    }
}
@-o-keyframes SkeletonAnimation {
    to{
        opacity: 0.8;
    }
} 
@keyframes SkeletonAnimation {
    to{
        opacity: 0.8;
    }
}

.skeleton-t-logo{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 5px solid #eee;
}
.skeleton-od-image{
    height: 200px;
    background-color: #ccc;
}

.placeholder{
    display: block !important;
}