@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Medium.woff2') format('woff2'),
        url('SFProText-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Light.woff2') format('woff2'),
        url('SFProText-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Bold.woff2') format('woff2'),
        url('SFProText-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Regular.woff2') format('woff2'),
        url('SFProText-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'SFProText';
    src: url('SFProText-Semibold.woff2') format('woff2'),
        url('SFProText-Semibold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

