.vb-bg-image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 124px; */

  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}

.vb-logo {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 5px solid #eee;
  object-fit: contain;
}


.dropdown-toggle::after {
  display: none !important;
}


.header-text {
  font-family: 'PT Sans Narrow', sans-serif !important;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 8vw;
  position: absolute;
}

@media (min-width: 576px) {
  .header-text {
    font-size: 8vw;
  }

  .vb-logo {
    width: 140px;
    height: 140px;
  }

  /* .vb-bg-image {
      height: 124px;
    } */
}

@media (min-width: 768px) {
  .header-text {
    font-size: 5vw;
  }

  .vb-logo {
    width: 180px;
    height: 180px;
  }

  /* .vb-bg-image {
      height: 300px;
    } */
}

@media (min-width: 992px) {
  .header-text {
    font-size: 5vw;
  }

  .vb-logo {
    width: 180px;
    height: 180px;
  }

  /* .vb-bg-image {
      height: 300px;
    } */
}

#divSearchContainer .bg-none {
  background-color: transparent;

}

#divSearchContainer .form-control {
  border-left: none;
}

*:focus {
  outline: none !important;
}