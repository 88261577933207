.catmaincontainer {
    overflow: hidden;
}

.catmaincontainer:hover {
    overflow: auto;
}

.categoryContainer {
    width: max-content;
}

.category {
    width: auto;
    margin: 0 30px;
    /* border:1px solid #ddd; */
    /* margin: 5px; */
    text-align: center;
    font-size: 16px;
    line-height: 24px;
}

@media (max-width: 767px) {
    .category {
        margin: 0 10px;
    }
    .catmaincontainer {
        overflow: auto;
    }
}

.category img {
    padding: 5px;
    width: 50px;
    height: 50px;
    object-fit: none;
}

.category.catactive img {
    border: 3px solid #FFD23A;
    border-radius: 50%;
}

